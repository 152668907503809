import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    width: 70%;
    display: inline-flex;
    margin: 0 auto;
    opacity: .5;
    animation: 1s ease-out 0s 1 slideInFromLeft;

    div{
        height: 10px;
        width: 25%;
    }

    .yellow{
        background: var(--yellow);
    }

    .red{
        background: var(--red)
    }

    .green{
        background: var(--green)
    }

    .blue{
        background: var(--blue)
    }

    @keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
`

const Colors = () => {
    return(
        <Container>
            <div className="yellow"></div>
            <div className="red"></div>
            <div className="green"></div>
            <div className="blue"></div>
        </Container>
    )
}

export default Colors;